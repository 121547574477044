import { Box, useMediaQuery } from "@mui/material";
import { MenuButton } from "./Components";
import LBApp from "./Pages/LBApp";

import { useTheme } from "./Contexts/ThemeContext";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useActiveIndex } from "./Contexts/ActiveContext";
export default function MainApp() {
  const isDesktop = useMediaQuery("(min-width:768px)");
  const name = isDesktop ? "vector.png" : "vector-mobile.png";

  const { theme, setTheme } = useTheme();
  const [themeIcon, setThemeIcon] = useState("");
  const [logo, setLogo] = useState("");
  const { activeIndex, setActiveIndex } = useActiveIndex();
  const navigate = useNavigate();
  const onThemeSwitch = (e) => {
    console.log("theme was switched");
    let newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
  };

  useEffect(() => {
    if (theme == "light") {
      setThemeIcon("moon.png");
      setLogo("black_mun.svg");
    } else {
      setThemeIcon("sun.png");
      setLogo("white_mun.svg");
    }
  }, [theme]);
  if (isDesktop) {
    return (
      <Box className="flex flex-row h-screen overflow-hidden">
        <Box className="w-[121px] h-full bg-[var(--menu-bg)] stroke-[#243c5a] flex  flex-col items-center border-[1px] border-[var(--menu-border)] border-solid">
          <img
            src={`/images/${logo}`}
            width={75}
            // className="basis-1/4 mt-[30px]"
            style={{ paddingTop: "30px", paddingBottom: "80px" }}
          ></img>

          <Box className="gap-[40px] flex flex-col flex-1">
            {/* <MenuButton
              imagename="menu/portfollio.svg"
              text="PORTFOLIO"
              active={activeIndex == 0}
              onClick={() => {
                setActiveIndex(0);
                navigate("/portfollio");
              }}
            ></MenuButton> */}
            <MenuButton
              imagename="menu/lend_borrow.svg"
              onClick={() => {
                console.log("lend borrorw was clicked");
                setActiveIndex(1);
                navigate("/");
              }}
              active={activeIndex == 1}
            >
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: 700,
                  fontFamily: "GoodTimes",
                  lineHeight: "14px",
                  color: "var(--mobile-menu-item-color)",
                }}
                className="flex flex-col items-center gap-[5px]"
              >
                <p style={{ fontFamily: "GoodTimes" }}>LEND/</p>
                <p style={{ fontFamily: "GoodTimes" }}>BORROW</p>
              </div>
            </MenuButton>
            {/* <MenuButton
              imagename="menu/swap.svg"
              text="SWAP"
              onClick={() => {
                setActiveIndex(2);
                navigate("/swap");
              }}
              active={activeIndex === 2}
            ></MenuButton> */}
            <MenuButton
              imagename="menu/munlock.svg"
              text="MUNLOCK"
              onClick={() => {
                setActiveIndex(3);
                navigate("/lock");
              }}
              active={activeIndex == 3}
            ></MenuButton>
            <MenuButton
              imagename="menu/shield.svg"
              text="MUNSHIELD"
              onClick={() => {
                setActiveIndex(4);
                navigate("/shield");
              }}
              active={activeIndex == 4}
            ></MenuButton>
          </Box>
          <img
            src={`/images/${themeIcon}`}
            width={38}
            onClick={onThemeSwitch}
            style={{ paddingBottom: "60px" }}
          ></img>
        </Box>
        <div
          className="flex-1 basis-1/4 overflow-scroll"
          style={{
            background:
              "linear-gradient(225.43deg, var(--bg-color1) -191.21%, var(--bg-color2) 68.75%), var(--bg-color3)",
          }}
        >
          <LBApp></LBApp>
        </div>
      </Box>
    );
  } else {
    return (
      <Box
        className="flex flex-col h-screen overflow-hidden gap-[0px] "
        style={{
          background:
            "linear-gradient(225.43deg, var(--bg-color1) -191.21%, var(--bg-color2) 68.75%), var(--bg-color3)",
        }}
      >
        <div className="flex-1  overflow-scroll w-full mb-[66.6px]">
          <LBApp></LBApp>
        </div>
        <Box className="fixed bottom-[0px] w-full bg-[var(--menu-bg)] stroke-[#243c5a] flex  flex-row items-center overflow-scroll justify-center p-[10px] items-center border border-solid border-[var(--subwindow-stroke)] pl-[16px]">
          {/* <MenuButton
            imagename={logo}
            text=""
            className="basis-1/4 mt-[30px]"
            styleProps={{
              paddingTop: "30px",
              paddingBottom: "80px",
            }}
            size={20}
          ></MenuButton> */}

          <img src={`/images/${logo}`} width={39}></img>
          <Box className="flex flex-row flex-1  mr-[15px] ml-[15px] px-[10px] gap-[10px] items-center">
            {/* <MenuButton
              imagename="menu/portfollio.svg"
              text="PORTFOLIO"
              active={activeIndex == 0}
              onClick={() => {
                setActiveIndex(0);
                navigate("/portfollio");
              }}
              size={20}
              style={{ flex: 1 }}
            ></MenuButton> */}
            <MenuButton
              imagename="menu/lend_borrow.svg"
              onClick={() => {
                console.log("lend borrorw was clicked");
                setActiveIndex(1);
                navigate("/");
              }}
              size={24}
              active={activeIndex == 1}
            >
              <div
                style={{
                  fontSize: "8px",
                  fontWeight: 700,

                  lineHeight: "10px",
                  color: "var(--mobile-menu-item-color)",
                }}
                className="flex flex-col items-center"
              >
                <span style={{ fontFamily: "GoodTimes" }}>LEND/BORROW</span>
              </div>
            </MenuButton>

            <MenuButton
              imagename="menu/munlock.svg"
              text="MUNLOCK"
              onClick={() => {
                setActiveIndex(3);
                navigate("/lock");
              }}
              active={activeIndex == 3}
              size={24}
            ></MenuButton>
            <MenuButton
              imagename="menu/shield.svg"
              text="MUNSHIELD"
              onClick={() => {
                setActiveIndex(4);
                navigate("/shield");
              }}
              active={activeIndex == 4}
              size={24}
            ></MenuButton>
          </Box>
          <img
            src={`/images/${themeIcon}`}
            width={20}
            onClick={onThemeSwitch}
          ></img>
        </Box>
      </Box>
    );
  }
}
