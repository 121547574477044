import { ButtonText } from "./MUNText";
import { Box, useMediaQuery } from "@mui/material";

import styled from "styled-components";

export const ColorButton = styled(ButtonText)`
  background: #5c92ff;
  border-radius: 40px;
  padding: 13px 20px;
  cursor: pointer;
  color: white;

  @media (max-width: 1535px) {
    padding: 13px 20px;
  }
`;

export const InterestButton = styled(ButtonText)`
  border-radius: 6px;
  padding: 9px;
  cursor: pointer;
`;

export const BorderButton = styled(ButtonText)`
  border: 1px solid #5c88e1;
  border-radius: 40px;
  padding: 13px 10px;
  cursor: pointer;
`;

export const BorderToggleButton = styled("div")`
  border: 1px solid #5c88e1;
  border-radius: 40px;
  padding: 13px 2px;
  cursor: pointer;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  max-width: 100px;
  min-width: 100px;
  /* identical to box height, or 78% */

  @media (max-width: 1750px) {
    font-size: 12px;
    line-height: 10px;
    min-width: 70px;
    max-width: 70px;
  }

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 10px;
    min-width: 60px;
    max-width: 60px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 10px;
    min-width: 60px;
    max-width: 1000px;
  }

  color: var(--primary-color);
`;

export function ShareButton(props) {
  return (
    <ButtonText
      sx={{
        background: "#100B27",
        border: "1px solid #5C88E1",
        borderRadius: "40px",
        padding: "13px 20px",
        cursor: "pointer",
        width: "fit-content",
        display: "flex",
      }}
      {...props}
    >
      {props.children}
      <span className="mr-[13px]" />
      <img className="h-[1em]" src="/images/home/Vector.png" alt="Vector" />
    </ButtonText>
  );
}

export const AmountButton = styled(ButtonText)`
  background: var(--subwindow-stroke);
  border-radius: 96px;
  padding: 8px;
  cursor: pointer;

  color: var(--primary-color);
  width: 30px;
  height: 30px;
  font-size: 20px;
  text-align: center;

  @media (max-width: 1535px) {
    font-size: 14px;
    width: 25px;
    height: 25px;
  }
`;

export const CollectionButton = styled(BorderButton)`
  font-size: 13px;
  width: 150px;
  text-align: center;

  @media (max-width: 1535px) {
    font-size: 10px;
    width: 125px !important;
  }
`;

export const CollectionColorButton = styled(ColorButton)`
  font-size: 16px;
  font-family: "GoodTimes" !important;
  width: 174px;
  padding: 20px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (max-width: 1535px) {
    font-size: 10px;
    width: 116px;
  }
`;

export function MenuButton(props) {
  const isDesktop = useMediaQuery("(min-width:768px)");

  return (
    <Box
      className={
        "flex items-center flex-col justify-start  hover:bg-[var(--mobile-menu-active-color)] hover:rounded-[18px] hover: cursor-pointer p-[10px] " +
        (props.active === true
          ? "bg-[var(--mobile-menu-active-color)] rounded-[18px]"
          : "") +
        (isDesktop ? " gap-[10px]" : " gap-[1px]") +
        (isDesktop ? " p-[10px]" : " p-[9px]")
      }
      style={{ ...props.styleProps }}
      onClick={props.onClick}
    >
      <img src={"/images/" + props.imagename} width={props.size ?? 40}></img>

      {props.text && (
        <Box
          style={{
            fontSize: isDesktop ? "12px" : "8px",
            fontWeight: 700,
            fontFamily: "GoodTimes",
            lineHeight: isDesktop ? "14px" : "10px",
            color: "var(--mobile-menu-item-color)",
          }}
        >
          {props.text}
        </Box>
      )}
      {props.children}
    </Box>
  );
}
