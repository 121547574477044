import SimpleContainer from "../Container/SimpleContainer";
import { LandingCaptionText, LandingHeaderText } from "../../Components";
import { Box, useMediaQuery } from "@mui/material";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useContext, useEffect, useState } from "react";
import DialogContext from "../../Contexts/dialogContext";
import { ShareItemHeader } from "../../Components";
import styled from "styled-components";
import { CollectionTitleText } from "../../Components";
import { CollectionColorButton } from "../../Components";
// import { Checkbox } from "@mui/material";
import { MunF21W600 } from "../../Components";
import { SolanaItem } from "../../Components";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { SolanaText } from "../../Components";
import { InterestButton } from "../../Components";
import { CollectionButton } from "../../Components";
import { AmountInput } from "../../Components";
import { LockOverViewText } from "../../Components";
import { LockOverViewNumber } from "../../Components";
import { LockSelectDescription } from "../../Components";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { LockPoolHeaderItem, LockPoolTableItem } from "../../Components";

const TokensTab = styled(ShareItemHeader)`
  background: var(--locktab-color);

  border-color: #5c84ff;
  border-radius: 50px;
  padding: 16px 35px;
  cursor: pointer;
  text-align: center;
  width: 150px;
  color: var(--dashboard-text-color);
  @media (max-width: 1024px) {
    padding: 8px 30px;
    font-size: 14px;
    line-height: 22px;
    width: 130px;
  }
`;

const NFTSTab = styled(ShareItemHeader)`
  background: var(--locktab-color);
  border-color: #5c84ff;
  border-radius: 50px;
  margin-left: -50px;
  padding: 16px 8px;
  cursor: pointer;
  text-align: center;
  width: 150px;
  color: var(--dashboard-text-color);
  @media (max-width: 1024px) {
    padding: 8px 30px;
    font-size: 14px;
    line-height: 22px;
    width: 130px;
    margin-left: -30px;
  }
`;

const GreyBackgroundCheckbox = withStyles((theme) => ({
  root: {
    color: "transparent",
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 3,
      top: 3,
      height: 18,
      width: 18,
      position: "absolute",
      backgroundColor: "var(--checkbox-item-color)",
      zIndex: -1,
    },
    "&$checked": {
      color: "var(--checkbox-item-color)",
    },
  },
  checked: {},
}))(Checkbox);

export default function Shield() {
  const [activeTab, setActiveTab] = useState(0);
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const { connection } = useConnection();
  const diagCtx = useContext(DialogContext);

  const wallet = useWallet();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SimpleContainer>
      <Box className="mt-[30px] mx-[20px] lg:mt-[30px] lg:mx-[120px] xl:mx-[360px] 2xl:mx-[480px]">
        <Box className="flex flex-row">
          <Box
            className="pt-[13px] sm:pt-[15px] 2xl:pt-[30px] "
            style={{ width: "7px", height: "auto", marginRight: "20px" }}
          >
            <Box className="w-[7px] bg-[#5C84FF] rounded-[8px] h-[40px] lg:h-[80px]" />
          </Box>
          <Box className="flex flex-col">
            <LandingHeaderText className="!font-GoodTime">
              MUNSHIELD
            </LandingHeaderText>
            <LandingCaptionText
              className="mb-[40px] lg:mb-[60px] xl:mb-[80px] 2xl:mb-[40px]"
              style={{ color: "#9395AA" }}
            >
              MunShield is a Telegram bot which can quickly help you identify
              {isDesktop && <br />}
              scams by checking a token safety parameters (lp locked, freeze
              {isDesktop && <br />}/ mint authorities are revoked and more...).
            </LandingCaptionText>
            <CollectionColorButton
              style={{ width: "100%" }}
              onClick={() => {
                window.open("https://t.me/munshieldbot", "_blank");
              }}
            >
              LAUNCH BOT
            </CollectionColorButton>
          </Box>
        </Box>
      </Box>
    </SimpleContainer>
  );
}
