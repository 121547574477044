import { Box, useMediaQuery } from "@mui/material";

import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useTheme, useState } from "../../Contexts/ThemeContext";
import { useActiveIndex } from "../../Contexts/ActiveContext";

export default function Container(props) {
  const { theme, setTheme } = useTheme();
  const { activeIndex, setActiveIndex } = useActiveIndex();

  const isDesktop = useMediaQuery("(min-width:768px)");
  // const name = isDesktop ? "vector.png" : "vector-mobile.png";
  const name = theme === "light" ? "vector_black.svg" : "vector_white.svg";
  return (
    <Box
      className="bg-no-repeat bg-contain"
      style={{ backgroundImage: `url(/images/${name})` }}
    >
      <Header></Header>
      {/* {isDesktop && <Sidebar />} */}
      {props.children}
      <div className="mb-[22px] lg:mb-[120px]" />
      <Footer />
    </Box>
  );
}
