import SimpleContainer from "../Container/SimpleContainer";
import { LandingCaptionText, LandingHeaderText } from "../../Components";
import { Box, useMediaQuery } from "@mui/material";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useContext, useEffect, useState } from "react";
import DialogContext from "../../Contexts/dialogContext";
import { ShareItemHeader } from "../../Components";
import styled from "styled-components";
import { CollectionTitleText } from "../../Components";
import { CollectionColorButton } from "../../Components";
// import { Checkbox } from "@mui/material";
import { MunF21W600 } from "../../Components";
import { SolanaItem } from "../../Components";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { SolanaText } from "../../Components";
import { InterestButton } from "../../Components";
import { CollectionButton } from "../../Components";
import { AmountInput } from "../../Components";
import { LockOverViewText } from "../../Components";
import { LockOverViewNumber } from "../../Components";
import { LockSelectDescription } from "../../Components";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { LockPoolHeaderItem, LockPoolTableItem } from "../../Components";

const TokensTab = styled(ShareItemHeader)`
  background: var(--locktab-color);

  border-color: #5c84ff;
  border-radius: 50px;
  padding: 16px 35px;
  cursor: pointer;
  text-align: center;
  width: 150px;
  color: var(--dashboard-text-color);
  @media (max-width: 1024px) {
    padding: 8px 30px;
    font-size: 14px;
    line-height: 22px;
    width: 130px;
  }

  @media (max-width: 768px) {
    padding: 8px 5px;
  }
`;

const NFTSTab = styled(ShareItemHeader)`
  background: var(--locktab-color);
  border-color: #5c84ff;
  border-radius: 50px;
  margin-left: -50px;
  padding: 16px 8px;
  cursor: pointer;
  text-align: center;
  width: 150px;
  color: var(--dashboard-text-color);
  @media (max-width: 1024px) {
    padding: 8px 30px;
    font-size: 14px;
    line-height: 22px;
    width: 130px;
    margin-left: -30px;
  }
`;

const GreyBackgroundCheckbox = withStyles((theme) => ({
  root: {
    color: "transparent",
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 3,
      top: 3,
      height: 18,
      width: 18,
      position: "absolute",
      backgroundColor: "var(--checkbox-item-color)",
      zIndex: -1,
    },
    "&$checked": {
      color: "var(--checkbox-item-color)",
    },
  },
  checked: {},
}))(Checkbox);

export default function Lock() {
  const [activeTab, setActiveTab] = useState(0);
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const { connection } = useConnection();
  const diagCtx = useContext(DialogContext);

  const wallet = useWallet();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SimpleContainer>
      <Box className="mt-[30px] mx-[20px] lg:mt-[30px] lg:mx-[120px] xl:mx-[360px] 2xl:mx-[480px]">
        <Box className="flex flex-row">
          <Box
            className="pt-[13px] sm:pt-[15px] 2xl:pt-[30px] "
            style={{ width: "7px", height: "auto", marginRight: "20px" }}
          >
            <Box className="w-[7px] bg-[#5C84FF] rounded-[8px] h-[40px] lg:h-[80px]" />
          </Box>
          <Box className="flex flex-col">
            <LandingHeaderText className="!font-GoodTime">
              MUNLOCK
            </LandingHeaderText>
            <LandingCaptionText
              className="mb-[40px] lg:mb-[60px] xl:mb-[80px] 2xl:mb-[100px]"
              style={{ color: "#9395AA" }}
            >
              <div>
                Connect and Secure your wallet from rugs in a few seconds using
                {isDesktop && <br />}
                MunLock. Our Smart contract will lock the assets in your wallet,
                {isDesktop && <br />}
                making it rug proof.
              </div>
            </LandingCaptionText>
          </Box>
        </Box>
        <Box
          style={{
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "18px",
            color: "#848D9E",
            marginLeft: "20px",
          }}
        >
          <p style={{ margin: "10px" }}>
            Connected Wallet: 9yDJTW6xq.....Z8Sz5d8
          </p>
        </Box>

        <Box
          className={`border border-solid border-none bg-[var(--locktable-bg-color)] rounded-[12px] md:px-[2px] px-[2px] py-[0px]  2xl: bg-[var(--subwindow-main-bg)] grid gap-[15px] 2xl:gap-[15px] `}
        >
          <Box className="flex flex-wrap px-[25px] py-[25px] bg-[var(--locktab-color)] rounded-[12px] justify-between  items-end">
            <div className="text-white flex-col items-center justify-center text-center">
              <LockOverViewText>Token</LockOverViewText>
              <LockOverViewNumber>17</LockOverViewNumber>
            </div>
            <div className="text-white flex-col items-center justify-center text-center">
              <LockOverViewText>NFTs</LockOverViewText>
              <LockOverViewNumber>58</LockOverViewNumber>
            </div>
            <div className="text-white flex flex-col justify-center text-center items-center">
              <LockOverViewText>Total Value</LockOverViewText>
              <div className="flex space-between items-baseline gap-[5px]">
                <LockOverViewNumber>127.32</LockOverViewNumber>
                <img
                  className="h-[15px]"
                  src="/images/sol.png"
                  alt="SolanaText"
                />
              </div>
            </div>
            {isDesktop ? (
              <div className="text-white flex flex-col items-center justify-center text-center">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.8757 13.125L16.0423 18.9583L13.1257 16.0417M29.1673 14.824C29.1673 24.4027 21.9222 28.6972 18.8507 30.0874L18.8469 30.0891C18.5238 30.2354 18.3619 30.3086 17.9948 30.3717C17.7624 30.4116 17.2404 30.4116 17.0079 30.3717C16.6394 30.3084 16.4758 30.2348 16.1502 30.0875C13.0787 28.6972 5.83398 24.4027 5.83398 14.824V9.04195C5.83398 7.40847 5.83398 6.59111 6.15188 5.9672C6.43151 5.4184 6.87738 4.97253 7.42619 4.6929C8.0501 4.375 8.86745 4.375 10.5009 4.375H24.5009C26.1344 4.375 26.95 4.375 27.5739 4.6929C28.1227 4.97253 28.5701 5.4184 28.8497 5.9672C29.1673 6.5905 29.1673 7.40687 29.1673 9.03716V14.824Z"
                    stroke="#38D39C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <LockOverViewNumber
                  style={{ marginTop: "0px", color: "#38D39C" }}
                >
                  45% secured
                </LockOverViewNumber>
              </div>
            ) : (
              <div className="text-white flex items-center justify-center text-center items-center w-full mt-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.8757 13.125L16.0423 18.9583L13.1257 16.0417M29.1673 14.824C29.1673 24.4027 21.9222 28.6972 18.8507 30.0874L18.8469 30.0891C18.5238 30.2354 18.3619 30.3086 17.9948 30.3717C17.7624 30.4116 17.2404 30.4116 17.0079 30.3717C16.6394 30.3084 16.4758 30.2348 16.1502 30.0875C13.0787 28.6972 5.83398 24.4027 5.83398 14.824V9.04195C5.83398 7.40847 5.83398 6.59111 6.15188 5.9672C6.43151 5.4184 6.87738 4.97253 7.42619 4.6929C8.0501 4.375 8.86745 4.375 10.5009 4.375H24.5009C26.1344 4.375 26.95 4.375 27.5739 4.6929C28.1227 4.97253 28.5701 5.4184 28.8497 5.9672C29.1673 6.5905 29.1673 7.40687 29.1673 9.03716V14.824Z"
                    stroke="#38D39C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <LockOverViewNumber
                  style={{ marginTop: "0px", color: "#38D39C" }}
                >
                  45% secured
                </LockOverViewNumber>
              </div>
            )}
          </Box>
          <Box></Box>
          <Box>
            <LockSelectDescription>
              Select the assets you want to lock
            </LockSelectDescription>
            <Box>
              <Box className="flex mb-[32px] mx-[20px] justify-between">
                <div className="flex gap-[20px]">
                  <TokensTab
                    className={`${
                      activeTab == 0 ? "border-[1px] z-[10]" : "z-[0]"
                    }`}
                    onClick={() => setActiveTab(0)}
                  >
                    Tokens
                  </TokensTab>
                  <NFTSTab
                    className={`${
                      activeTab == 1 ? "border-[1px] z-[10]" : "z-[0]"
                    }`}
                    onClick={() => setActiveTab(1)}
                  >
                    NFTs
                  </NFTSTab>
                </div>
                {isDesktop && (
                  <div className="flex mx-[10px]">
                    <GreyBackgroundCheckbox></GreyBackgroundCheckbox>
                    <CollectionTitleText className="my-auto break-all">
                      Choose All Assets
                    </CollectionTitleText>
                  </div>
                )}
              </Box>
              {!isDesktop && (
                <div className="flex mx-[10px]">
                  <GreyBackgroundCheckbox></GreyBackgroundCheckbox>
                  <CollectionTitleText className="my-auto break-all">
                    Choose All Assets
                  </CollectionTitleText>
                </div>
              )}
            </Box>
          </Box>
          <Box className="flex flex-col gap-[10px] mx-[20px] text-[var(--locktable-item-color)] font-[600]">
            <Box className="flex items-center justify-between flex-wrap px-[10px] py-[10px] bg-[var(--subwindow-header-bg)] rounded-[12px]  ">
              <Box className="flex gap-[20px] items-center ]">
                {/* <Checkbox
                  checked={false}
                  // onChange={(e) => setChooseAll(e.target.checked)}
                  sx={{
                    color: "#3E4162",
                    "&.Mui-checked": {
                      color: "var(--primary-color)",
                    },
                  }}
                /> */}
                <GreyBackgroundCheckbox></GreyBackgroundCheckbox>
                <img
                  src="images/mint/Token.png"
                  alt="SolanaText"
                  width={40}
                ></img>
                <p>BONK</p>
                <p
                  className="var(--locktable-end-item-color)"
                  style={{ marginLeft: "30px" }}
                >
                  113.568
                </p>
              </Box>
              <p className="text-[var(--locktable-end-item-color)]">156.7$</p>
            </Box>
            <Box className="flex items-center justify-between flex-wrap px-[10px] py-[10px] bg-[var(--subwindow-header-bg)] rounded-[12px]">
              <Box className="flex gap-[20px] items-center">
                <GreyBackgroundCheckbox></GreyBackgroundCheckbox>
                <img
                  src="images/mint/Token.png"
                  alt="SolanaText"
                  width={40}
                ></img>
                <p>BONK</p>
                <p style={{ marginLeft: "30px" }}>113.568</p>
              </Box>
              <p className="text-[var(--locktable-end-item-color)]">156.7$</p>
            </Box>
            <Box>
              <CollectionColorButton style={{ width: "100%" }}>
                SECURE ASSETS
              </CollectionColorButton>
            </Box>
          </Box>
        </Box>
        <Box className="mb-[20px] lg:mb-[30px] xl:mb-[45px] 2xl:mb-[60px] mt-[30px]">
          {isDesktop ? (
            <Box className="2xl:mx-[-140px]">
              <MunF21W600 className="mb-[10px] sm:mb-[20px]">
                My Locks
              </MunF21W600>
              <LandingCaptionText
                className="mb-[10px] lg:mb-[30px] xl:mb-[45px] 2xl:mb-[30px] "
                style={{ color: "#9395AA", fontSize: "18px" }}
              >
                View your locks and cancel them anytime.
                <br />
              </LandingCaptionText>

              <Box
                className="hidden lg:grid gap-[10px] 2xl:gap-[30px]"
                gridTemplateColumns={"2fr 1fr 1fr 1.5fr 1fr 2.5fr"}
              >
                <LockPoolHeaderItem
                  style={{ textAlign: "start", paddingLeft: "20px" }}
                >
                  Assets
                </LockPoolHeaderItem>
                <LockPoolHeaderItem style={{ textAlign: "center" }}>
                  Tokens
                </LockPoolHeaderItem>
                <LockPoolHeaderItem style={{ textAlign: "center" }}>
                  NFTs
                </LockPoolHeaderItem>
                <LockPoolHeaderItem style={{ textAlign: "center" }}>
                  Lock Duration
                </LockPoolHeaderItem>
                <LockPoolHeaderItem style={{ textAlign: "center" }}>
                  Value
                </LockPoolHeaderItem>
                <LockPoolHeaderItem
                  style={{ textAlign: "center" }}
                ></LockPoolHeaderItem>
                <Box className="2xl:min-w-[200px]"></Box>
              </Box>

              <Box
                className={` py-[18px] mb-[20px] bg-[var(--subwindow-main-bg)] rounded-[12px] grid gap-[5px] 2xl:gap-[30px] border border-solid border-[var(--subwindow-stroke)] text-white`}
                gridTemplateColumns={"2fr 1fr 1fr 1.5fr 1fr 2.5fr"}
                style={{ minWidth: "fit-content" }}
              >
                <Box className="grid grid-cols-3 gap-[10px] px-[10px]">
                  <img
                    className="my-auto "
                    src="images/mint/Token.png"
                    alt="SolanaText"
                  />
                  <img
                    className="my-auto "
                    src="images/mint/Token.png"
                    alt="SolanaText"
                  />
                  <img
                    className="my-auto "
                    src="images/mint/Token.png"
                    alt="SolanaText"
                  />
                  <img
                    className="my-auto "
                    src="images/mint/Token.png"
                    alt="SolanaText"
                  />
                  <img
                    className="my-auto "
                    src="images/mint/Token.png"
                    alt="SolanaText"
                  />
                  <img
                    className="my-auto "
                    src="images/mint/Token.png"
                    alt="SolanaText"
                  />
                </Box>
                <Box className="flex items-center justify-center">
                  <LockPoolTableItem>5</LockPoolTableItem>
                </Box>
                <Box className="flex items-center justify-center">
                  <LockPoolTableItem>0</LockPoolTableItem>
                </Box>
                <Box className="flex flex-col items-center justify-center text-[10px] gap-2">
                  <div
                    style={{
                      textAlign: "center",
                      color: "#9395AA",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "19px",
                    }}
                  >
                    Unlock Available
                  </div>
                  <Box className="rounded-[20px] bg-[#191E46] h-[20px] w-full">
                    <Box className="rounded-[20px] bg-[#A8B5E0] w-[100%] h-full" />
                  </Box>
                </Box>
                <Box className="flex items-center justify-center">
                  <LockPoolTableItem style={{ textAlign: "center" }}>
                    451$
                  </LockPoolTableItem>
                </Box>
                <Box className="flex items-center justify-center text-[15px] text-[#FFBE5C]">
                  <div
                    style={{ textAlign: "center" }}
                    className=" rounded-[30px] border-[#FFBE5C] border-2 p-2 flex items-center justify-center gap-2"
                  >
                    <div>Unlock Assets</div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 9H7.2002C6.08009 9 5.51962 9 5.0918 9.21799C4.71547 9.40973 4.40973 9.71547 4.21799 10.0918C4 10.5196 4 11.0801 4 12.2002V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.51921 21 6.07901 21 7.19694 21L16.8031 21C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V12.1969C20 11.079 20 10.5192 19.7822 10.0918C19.5905 9.71547 19.2837 9.40973 18.9074 9.21799C18.4796 9 17.9203 9 16.8002 9H9ZM9 9V6.12012C9 4.39699 10.3 3 11.9037 3C12.7277 3 13.4708 3.36879 13.9993 3.96113"
                        stroke="#FFBE5C"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className="flex flex-col items-start justify-between px-[10px] py-[10px] rounded-[12px]  ">
              <MunF21W600 className="mb-[10px]" style={{ fontSize: "21px" }}>
                My Locks
              </MunF21W600>
              <LandingCaptionText
                className="mb-[10px] lg:mb-[30px] xl:mb-[45px] 2xl:mb-[30px] "
                style={{ color: "#9395AA", fontSize: "14px" }}
              >
                View your locks and cancel them anytime.
                <br />
              </LandingCaptionText>
              <Box className="flex flex-col gap-[20px]">
                <Box className="border border-solid border-none bg-[var(--locktable-bg-color)] rounded-[12px] w-full py-[10px]">
                  <LockPoolHeaderItem
                    style={{
                      textAlign: "start",
                      paddingLeft: "20px",
                      fontSize: "16px",
                    }}
                  >
                    Assets
                  </LockPoolHeaderItem>
                  <Box className="grid grid-cols-10 gap-[5px] px-[20px] py-[10px]">
                    <img
                      className="my-auto w-[60px] "
                      src="images/mint/Token.png"
                      alt="SolanaText"
                    />
                    <img
                      className="my-auto w-[60px] "
                      src="images/mint/Token.png"
                      alt="SolanaText"
                    />
                    <img
                      className="my-auto w-[60px] "
                      src="images/mint/Token.png"
                      alt="SolanaText"
                    />
                  </Box>
                  <Box className="grid grid-cols-3 gap-[5px]">
                    <Box className="flex items-center gap-[5px] px-[20px] py-[10px]">
                      <LandingCaptionText>Tokens</LandingCaptionText>
                      <CollectionTitleText>3</CollectionTitleText>
                    </Box>
                    <Box className="flex items-center gap-[5px] px-[20px] py-[10px]">
                      <LandingCaptionText>NFTS</LandingCaptionText>
                      <CollectionTitleText>0</CollectionTitleText>
                    </Box>
                    <Box className="flex items-center gap-[5px] px-[20px] py-[10px]">
                      <LandingCaptionText>Value</LandingCaptionText>
                      <CollectionTitleText>1452$</CollectionTitleText>
                    </Box>
                  </Box>
                  <Box>
                    <div
                      style={{
                        textAlign: "center",
                        color: "#9395AA",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "19px",
                      }}
                    >
                      Unlock Available
                    </div>
                    <Box className="rounded-[20px] bg-[#191E46] h-[20px] mx-[30px]">
                      <Box className="rounded-[20px] bg-[#A8B5E0] w-[100%] h-full" />
                    </Box>
                  </Box>
                  <Box className="flex justify-center w-full">
                    <div
                      style={{ textAlign: "center" }}
                      className=" rounded-[30px] border-[#FFBE5C] border-2 p-2 flex items-center justify-center gap-2 w-[300px] my-[10px]"
                    >
                      <div
                        style={{
                          fontFamily: "GoodTimes",
                          fontSize: "14px",
                          lineHeight: "14px",
                          color: "#FFBE5C",
                        }}
                      >
                        Unlock Assets
                      </div>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 9H7.2002C6.08009 9 5.51962 9 5.0918 9.21799C4.71547 9.40973 4.40973 9.71547 4.21799 10.0918C4 10.5196 4 11.0801 4 12.2002V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.51921 21 6.07901 21 7.19694 21L16.8031 21C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V12.1969C20 11.079 20 10.5192 19.7822 10.0918C19.5905 9.71547 19.2837 9.40973 18.9074 9.21799C18.4796 9 17.9203 9 16.8002 9H9ZM9 9V6.12012C9 4.39699 10.3 3 11.9037 3C12.7277 3 13.4708 3.36879 13.9993 3.96113"
                          stroke="#FFBE5C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </Box>
                </Box>
                <Box className="border border-solid border-none bg-[var(--locktable-bg-color)] rounded-[12px] w-full py-[10px]">
                  <LockPoolHeaderItem
                    style={{
                      textAlign: "start",
                      paddingLeft: "20px",
                      fontSize: "16px",
                    }}
                  >
                    Assets
                  </LockPoolHeaderItem>
                  <Box className="grid grid-cols-10 gap-[5px] px-[20px] py-[10px]">
                    <img
                      className="my-auto w-[60px] "
                      src="images/mint/Token.png"
                      alt="SolanaText"
                    />
                    <img
                      className="my-auto w-[60px] "
                      src="images/mint/Token.png"
                      alt="SolanaText"
                    />
                    <img
                      className="my-auto w-[60px] "
                      src="images/mint/Token.png"
                      alt="SolanaText"
                    />
                  </Box>
                  <Box className="grid grid-cols-3 gap-[5px]">
                    <Box className="flex items-center gap-[5px] px-[20px] py-[10px]">
                      <LandingCaptionText>Tokens</LandingCaptionText>
                      <CollectionTitleText>3</CollectionTitleText>
                    </Box>
                    <Box className="flex items-center gap-[5px] px-[20px] py-[10px]">
                      <LandingCaptionText>NFTS</LandingCaptionText>
                      <CollectionTitleText>0</CollectionTitleText>
                    </Box>
                    <Box className="flex items-center gap-[5px] px-[20px] py-[10px]">
                      <LandingCaptionText>Value</LandingCaptionText>
                      <CollectionTitleText>1452$</CollectionTitleText>
                    </Box>
                  </Box>
                  <Box>
                    <div
                      style={{
                        textAlign: "center",
                        color: "#9395AA",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "19px",
                      }}
                    >
                      Unlock Available
                    </div>
                    <Box className="rounded-[20px] bg-[#191E46] h-[20px] mx-[30px]">
                      <Box className="rounded-[20px] bg-[#A8B5E0] w-[100%] h-full" />
                    </Box>
                  </Box>
                  <Box className="flex justify-center w-full">
                    <div
                      style={{ textAlign: "center" }}
                      className=" rounded-[30px] border-[#FFBE5C] border-2 p-2 flex items-center justify-center gap-2 w-[300px] my-[10px]"
                    >
                      <div
                        style={{
                          fontFamily: "GoodTimes",
                          fontSize: "14px",
                          lineHeight: "14px",
                          color: "#FFBE5C",
                        }}
                      >
                        Unlock Assets
                      </div>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 9H7.2002C6.08009 9 5.51962 9 5.0918 9.21799C4.71547 9.40973 4.40973 9.71547 4.21799 10.0918C4 10.5196 4 11.0801 4 12.2002V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.51921 21 6.07901 21 7.19694 21L16.8031 21C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V12.1969C20 11.079 20 10.5192 19.7822 10.0918C19.5905 9.71547 19.2837 9.40973 18.9074 9.21799C18.4796 9 17.9203 9 16.8002 9H9ZM9 9V6.12012C9 4.39699 10.3 3 11.9037 3C12.7277 3 13.4708 3.36879 13.9993 3.96113"
                          stroke="#FFBE5C"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </SimpleContainer>
  );
}
