import { createContext, useState, useContext } from "react";

const ActiveContext = createContext();

export const ActiveProvider = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(1);

  return (
    <ActiveContext.Provider value={{ activeIndex, setActiveIndex }}>
      {children}
    </ActiveContext.Provider>
  );
};

export const useActiveIndex = () => useContext(ActiveContext);
