import { Box, useMediaQuery } from "@mui/material";
import Header from "./Header";
import { useTheme } from "../../Contexts/ThemeContext";

export default function SimpleContainer(props) {
  const { theme, setTheme } = useTheme();

  const isDesktop = useMediaQuery("(min-width:768px)");
  // const name = isDesktop ? "vector.png" : "vector-mobile.png";
  const name = theme === "light" ? "vector_black.svg" : "vector_white.svg";
  return (
    <Box
      className="bg-no-repeat bg-contain"
      style={{ backgroundImage: `url(/images/${name})` }}
    >
      <Header isMenuShowing={false}></Header>
      {/* {isDesktop && <Sidebar />} */}
      {props.children}
      <div className="mb-[22px] lg:mb-[120px]" />
    </Box>
  );
}
