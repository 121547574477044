import { useState, useRef, useContext, useEffect } from "react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import axios from "axios";
import DialogContext from "../../Contexts/dialogContext";
import { Box, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  NavTextActive,
  NavText,
  ColorButton,
  MobileNavText,
} from "../../Components";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import "./WalletMultiButton.css";

import { location } from "../../Api/config";
import { getUserPFP } from "../../Api/fetch";

import * as anchor from "@project-serum/anchor";
import { Program } from "@project-serum/anchor";

import IDL from "../../Utility/Idl/idl.json";
import { TOKEN_PROGRAM_ID, NATIVE_MINT } from "@solana/spl-token";
import {
  deriveSCAccountPDA,
  deriveConfigurationAccountPDA,
  derivePoolAccountPDA,
  deriveNFTAccountPDA,
  deriveOrderAccountPDA,
} from "../../Utility/ts/helper";
import { useTheme } from "../../Contexts/ThemeContext";
const MUN_PROGRAM_ID = new anchor.web3.PublicKey(
  "HTHZhsB4gmyTbNnWGU7kC3hU1JP79YzedQuyUwfqRijy"
);

function NavItem({ active, title, url }) {
  const navigate = useNavigate();
  const className = "mr-[40px] 2xl:mr-[60px] cursor-pointer";
  return active === true ? (
    <Box className="flex flex-col justify-center items-center">
      <NavTextActive className={className} onClick={() => navigate(url)}>
        {title}
      </NavTextActive>
      <Box className="mr-[40px] 2xl:mr-[60px] mt-[7px] h-[7px] w-[7px] rounded-[7px] bg-[#5C84FF]"></Box>
    </Box>
  ) : (
    <Box className="flex flex-col justify-center">
      <NavText className={className} onClick={() => navigate(url)}>
        {title}
      </NavText>
      <Box
        className="mr-[40px] 2xl:mr-[60px] mt-[7px] h-[7px] w-[7px] rounded-[7px] bg-[#5C84FF]"
        style={{ opacity: 0 }}
      ></Box>
    </Box>
  );
}

function MobileNavItem({ active, title, url }) {
  const navigate = useNavigate();
  return active === true ? (
    <MobileNavText
      className="cursor-pointer bg-[var(--mobile-highlight-color)]"
      onClick={() => navigate(url)}
    >
      {title}
    </MobileNavText>
  ) : (
    <MobileNavText className="cursor-pointer" onClick={() => navigate(url)}>
      {title}
    </MobileNavText>
  );
}

const routes_client = [
  // { title: "Home", url: "/home" },
  { title: "Dashboard", url: "/my-profile", index: 1 },
  { title: "Lend", url: "/lend" },
  { title: "Borrow", url: "/borrow" },
];

const routes_admin = [
  // { title: "Home", url: "/home" },
  { title: "Dashboard", url: "/my-profile" },
  { title: "Lend", url: "/lend" },
  { title: "Borrow", url: "/borrow" },
  { title: "Lock", url: "/lock" },
  { title: "Shield", url: "/shield" },
  { title: "Admin", url: "/admin" },
];

export default function Header({
  isMenuShowing = true,
  activeIndex,
  setActiveIndex,
}) {
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const diagCtx = useContext(DialogContext);

  const hash = window.location.hash;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [pfpUri, setPfpUri] = useState(null);
  const { theme, setTheme } = useTheme();
  const [image, setImage] = useState("moon");
  const [logo, setLogo] = useState("white_mun");

  const profileRef = useRef(null);
  const wallet = useWallet();
  const walletModal = useWalletModal();
  const { connection } = useConnection();

  const initialize = async () => {
    const provider = new anchor.AnchorProvider(connection, wallet, {});
    anchor.setProvider(provider);

    const program = new Program(IDL, MUN_PROGRAM_ID, provider);

    try {
      const [configurationPubKey] = await deriveConfigurationAccountPDA(
        NATIVE_MINT,
        program.programId
      );

      console.log(configurationPubKey.toBase58());

      const configuration = await program.account.configuration.fetch(
        configurationPubKey
      );

      console.log(configuration.withdrawTaxVault.toBase58());
      console.log(provider.wallet.publicKey.toBase58());

      if (
        configuration.withdrawTaxVault.toBase58() ===
        provider.wallet.publicKey.toBase58()
      ) {
        diagCtx.setIsAdmin(true);
      }
    } catch (e) {
      diagCtx.showError(e.message);
      diagCtx.hideLoading();
      return;
    }
  };

  useEffect(() => {
    try {
      if (wallet?.connected && !wallet?.disconnecting && !wallet?.connecting) {
        initialize();
        /* getUserPFP(wallet.publicKey).then((res) => {
                    setPfpUri(res.pfp_uri);
                }); */
      }
      if (!wallet?.connected && !wallet?.disconnecting && !wallet?.connecting) {
        diagCtx.setIsAdmin(false);
        setPfpUri(null);
      }
    } catch (e) {
      diagCtx.showError(e.message);
    }
  }, [wallet]);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    if (theme === "dark") {
      setImage("sun");
      setLogo("white_mun");
    } else {
      setImage("moon");
      setLogo("black_mun");
    }
  }, [theme]);

  const handleProfileClick = (e) => {
    e.stopPropagation();
    if (!wallet.connected) {
      diagCtx.showWarning("You have to connect wallet to change PFP");
      walletModal.setVisible(true);
      return;
    }
    profileRef.current.value = null;
    profileRef.current.click();
  };

  const handleUploadProfile = async (e) => {
    if (e.target.files.length === 0) return;
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("fileName", e.target.files[0].name);
    formData.append("wallet_addr", wallet.publicKey);
    //        formData.append("wallet_addr", wallet.publicKey);
    try {
      const res = await axios.post(`${location}/uploadPFP`, formData);
      setPfpUri(res.data.pfp_uri);
      diagCtx.showSuccess("Successfully updated your PFP");
    } catch (ex) {
      diagCtx.showError("Invalid wallet address or bad connection");
      console.log(ex);
    }
    /*         _uploadProfile(id, e.target.files[0])
        .then((res) => {
            if (res.success === 1)
                refreshProfile();
        }) */
  };

  const onThemeSwitch = (e) => {
    let newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
  };

  if (isDesktop) {
    return (
      <Box className="pl-[40px] pr-[40px] pt-[60px] pb-[6px] xl:pb-[12px] flex items-center">
        {/* <img
          src={`/${logo}.svg`}
          className="cursor-pointer mr-[30px]"
          alt="Logo"
          style={{ width: 100 }}
          onClick={() => navigate("/")}
        /> */}
        {isMenuShowing &&
          (diagCtx.isAdmin
            ? routes_admin.map((item, i) => (
                <NavItem
                  key={i}
                  active={hash === `#${item.url}`}
                  title={item.title}
                  url={item.url}
                />
              ))
            : routes_client.map((item, i) => (
                <NavItem
                  key={i}
                  active={hash === `#${item.url}`}
                  title={item.title}
                  url={item.url}
                />
              )))}

        <span className="mr-auto" />
        <Box className="flex flex-row mt-[-10px]">
          {pfpUri != null ? (
            <Box
              className="bg-cover cursor-pointer my-auto mr-[10px] w-[25px] h-[25px] lg:w-[30px] lg:h-[30px] 2xl:w-[40px] 2xl:h-[40px] rounded-full"
              style={{ backgroundImage: `url(${pfpUri})` }}
              onClick={handleProfileClick}
            />
          ) : (
            ""
          )}
          <input
            type="file"
            name="file"
            accept="image/*"
            ref={profileRef}
            className="hidden"
            onChange={handleUploadProfile}
          />
          {/* <Button onClick={onThemeSwitch}>
            <img
              className="w-[18px] mr-[12px] lg:h-fit lg:mr-[18px] my-auto"
              src={`/images/${image}.png`}
            ></img>
          </Button> */}
          <ColorButton
            className="flex items-center cursor-pointer mr-[10px] h-[25px] lg:h-[30px] 2xl:h-[40px]"
            onClick={() => navigate("/mint")}
          >
            Get Our NFT
          </ColorButton>
          <WalletMultiButton
            style={{ color: "var(--header-color)", background: "red" }}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <>
        <Box className="py-[25px] px-[24px] flex">
          {/* <img
            className="w-[64px]"
            src={`/${logo}.svg`}
            alt="Logo"
            onClick={() => navigate("/")}
          /> */}
          <span className="mr-auto" />
          {/* <Button onClick={onThemeSwitch}>
            <img
              className="w-[18px] mr-[12px] lg:h-fit lg:mr-[18px] my-auto"
              src={`/images/${image}.png`}
            ></img>
          </Button> */}
          <img
            className="my-auto h-fit cursor-pointer"
            src={
              "/icons/" +
              (theme === "light" ? "menu_black.svg" : "menu_white.svg")
            }
            alt="Menu"
            onClick={() => setOpen(!open)}
          />
        </Box>
        {open && (
          <Box className="bg-[var(--menu-bg)] absolute w-full top-[50px] z-[20]">
            {diagCtx.isAdmin === true
              ? routes_admin.map((item, i) => {
                  return (
                    <MobileNavItem
                      key={i}
                      active={hash === `#${item.url}`}
                      title={item.title}
                      url={item.url}
                    />
                  );
                })
              : routes_client.map((item, i) => {
                  return (
                    <MobileNavItem
                      key={i}
                      active={hash === `#${item.url}`}
                      title={item.title}
                      url={item.url}
                    />
                  );
                })}
            <Box className="h-[60px] flex justify-center">
              <WalletMultiButton
                style={{
                  color: "var(--mobile-menu-text-color)",
                  fontWeight: 400,
                }}
              />
            </Box>
            <Box className="h-[60px] flex justify-center">
              <ColorButton
                className="flex items-center w-fit my-auto !h-[35px] bg-[var(--mobile-menu-text-color)]"
                style={{ color: "white" }}
                onClick={() => navigate("/mint")}
              >
                Get Our NFT
              </ColorButton>
            </Box>
          </Box>
        )}
      </>
    );
  }
}
