import { Box, useMediaQuery } from "@mui/material";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Home";
import Mint from "./Mint";
import Profile from "./Profile";
import Lend from "./Lend";
import Borrow from "./Borrow";
import Admin from "./Admin";
import Lock from "./Lock";
import Shield from "./Shield";

export default function LBApp() {
  const isDesktop = useMediaQuery("(min-width:768px)");
  return (
    <Box
      className="min-h-screen"
      onContextMenu={(e) => e.preventDefault()}
      sx={{
        background:
          "linear-gradient(225.43deg, var(--bg-color1) -191.21%, var(--bg-color2) 68.75%), var(--bg-color3)",
      }}
    >
      <Routes>
        {/* <Route path="home" exact element={<Home />} /> */}
        <Route path="mint" exact element={<Mint />} />
        <Route path="my-profile" exact element={<Profile />} />
        <Route path="lend" exact element={<Lend />} />
        <Route path="borrow" exact element={<Borrow />} />
        <Route path="admin" exact element={<Admin />} />
        <Route path="lock" exact element={<Lock />} />

        <Route path="shield" exact element={<Shield />} />

        <Route path="/" element={<Navigate to="/my-profile" />} />
      </Routes>
    </Box>
  );
}
